export const analyticsKey = "analytics";
export const analytics = "/analytics";

export const bannersDashboard = "banners-dashboard";

export const offers = "offers";
export const offersBySource = "offers-by-source";
export const offersByUsers = "offers-by-users";

export const offersRankByEvent = "rank-by-event";
export const partnersDashboard = "partners-dashboard";
export const checklist = "checklists";
export const checklistDashboard = "checklist-dashboard";
export const popularUsageChecklist = "popularUsageChecklist";
export const influencerDashboard = "influencer-dashboard";
export const articleDashboard = "article-dashboard";
export const contentUsageDashboard = "content-dashboard";
export const funnelAnalyticsDashboard = "funnel-analytics-dashboard";

export const screen = "screen";
export const screenDiscover = "screen-discover";
export const faqDashboard = "faq-dashboard";
export const discoverSearch = "discover-search";
export const appReviewModal = "app-review-modal";

export const geo = "geo";
export const geoAnalytics = "geo-analytics";
export const interestsDashboard = "interests-dashboard";

export const mainJourneyDashboard = "main-journey-dashboard";

export const tools = "tools";
export const birthPlan = "birth-plan";
export const engagementDashboard = "engagement-dashboard";
export const pollAnalytics = "poll";
export const loyalty = "loyalty";
export const loyaltyGeneral = "loyalty-general";
export const loyaltyComparison = "loyalty-comparison";
export const loyaltyProfileAndMissions = "loyalty-profile-and-missions";
export const loyaltyLockedContentAndFeatures =
  "loyalty-locked-content-and-features";

export const popular = "popular";
export const popularContent = "popular-content";
export const popularClicks = "popular-clicks";
export const recommendedContent = "recommended-content";
export const courses = "courses";
export const dailyDoula = "daily-doula";

export const content = "content";
export const articleContent = "article";
export const contentUsage = "content-usage";

export const users = "users";
//use in future
// export const usersChild = "users-child";
export const usersPregnancy = "users-pregnancy";

export const timestamp = "timestamp";
export const timeSpentApp = "time-spent-app";
export const timeSpentScreen = "time-spent-screen";
