import { Controller, useForm, useWatch } from "react-hook-form";
import { FormEdit, FormEditType } from "../../../components";
import { Alert, Col, Row } from "antd";
import {
  BJInputFormItem,
  BJSelectFormItem,
  BJSwitchFormItem,
} from "../../../components/theme";
import { LoyaltyPopupType } from "../../../utils";
import { useCountry, useLoyalty } from "../../../context";
import { useState } from "react";
import BJList from "../../../components/theme/components/BJList";
import { ChangeFeaturesOrderList } from "./ChangeFeaturesOrderList";
import { LevelFeatureModal } from "./LevelFeatureModal";
import _ from "lodash";
import { BJDeeplinkFormInput } from "../../../components/theme/molecules/formItems/BJDeeplinkFormInput";

type FormState = {
  addCtaButton: boolean;
  loyaltyPopup: PopupData;
  popupType: LoyaltyPopupType;
};

const options = [
  {
    key: "empty",
    display: "No type selected",
    value: "",
    disabledOption: true,
  },
  ...Object.entries(LoyaltyPopupType).map(([key, value]) => ({
    key: key,
    value: value,
    display: key,
  })),
];

const defaultValue: Partial<PopupData> = {
  features: [],
};

export const LoyaltyPopupForm = () => {
  const { currentCountry, primaryLocale } = useCountry();

  const { loyaltyPopups, loading, upsertLevelsData } = useLoyalty();
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<FormState>({
    defaultValues: {
      popupType: "" as LoyaltyPopupType,
      addCtaButton: false,
      loyaltyPopup: defaultValue,
    },
  });
  const [ordering, setOrdering] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<number>();

  const popupType = useWatch({ control, name: "popupType" });
  const addCtaButton = useWatch({ control, name: "addCtaButton" });

  const onSubmit = (data: FormState) => {
    upsertLevelsData({
      loyaltyPopups: {
        [popupType]: data.loyaltyPopup,
      },
    });
  };

  const columnSchema = [
    {
      title: "Title",
      dataIndex: ["translations", primaryLocale.key],
      key: "title",
    },
  ];

  return (
    <FormEdit
      loading={loading}
      enableSave={isDirty}
      editType={FormEditType.EDIT}
      title={"Level shortcut popup"}
      localeSupported
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      hideBackButton
    >
      {locale => (
        <>
          <Alert
            type="info"
            showIcon
            message={"Make sure to save your changes before changing the type"}
          />
          <BJSelectFormItem
            size="large"
            control={control}
            error={!!errors.popupType}
            label="Popup type"
            message={errors.popupType?.message}
            optionsList={options}
            fieldName={"popupType"}
            handleChange={value => {
              reset({
                popupType: value as LoyaltyPopupType,
                loyaltyPopup: _.cloneDeep(loyaltyPopups[value]) ?? defaultValue,
                addCtaButton: !!loyaltyPopups[value]?.buttonLink,
              });
            }}
          />
          {popupType && (
            <Row gutter={20}>
              <Col span={12}>
                <BJInputFormItem
                  label={`Title (${locale?.label})`}
                  control={control}
                  fieldName={`loyaltyPopup.translations.${locale?.key}.title`}
                  key={`loyaltyPopup.translations.${locale?.key}.title-${popupType}`}
                  required
                  error={
                    !!errors.loyaltyPopup?.translations?.[locale?.key]?.title
                  }
                  message={
                    errors.loyaltyPopup?.translations?.[locale?.key]?.title
                      ?.message
                  }
                />
                <BJInputFormItem
                  label={`Body (${locale?.label})`}
                  control={control}
                  fieldName={`loyaltyPopup.translations.${locale?.key}.body`}
                  key={`loyaltyPopup.translations.${locale?.key}.body-${popupType}`}
                  rows={4}
                  error={
                    !!errors.loyaltyPopup?.translations?.[locale?.key]?.body
                  }
                  message={
                    errors.loyaltyPopup?.translations?.[locale?.key]?.body
                      ?.message
                  }
                />
                <BJInputFormItem
                  label={`List title (${locale?.label})`}
                  control={control}
                  fieldName={`loyaltyPopup.translations.${locale?.key}.listTitle`}
                  key={`loyaltyPopup.translations.${locale?.key}.listTitle-${popupType}`}
                  error={
                    !!errors.loyaltyPopup?.translations?.[locale?.key]
                      ?.listTitle
                  }
                  message={
                    errors.loyaltyPopup?.translations?.[locale?.key]?.listTitle
                      ?.message
                  }
                />
                <BJSwitchFormItem
                  label="Add CTA Button"
                  control={control}
                  fieldName="addCtaButton"
                />
                {addCtaButton && (
                  <>
                    <BJInputFormItem
                      label={`Button text (${locale?.label})`}
                      control={control}
                      fieldName={`loyaltyPopup.translations.${locale?.key}.buttonText`}
                      key={`loyaltyPopup.translations.${locale?.key}.buttonText-${popupType}`}
                      required
                      error={
                        !!errors.loyaltyPopup?.translations?.[locale?.key]
                          ?.buttonText
                      }
                      message={
                        errors.loyaltyPopup?.translations?.[locale?.key]
                          ?.buttonText?.message
                      }
                    />
                    <BJDeeplinkFormInput
                      required
                      label={"Button link"}
                      title="Button link"
                      control={control}
                      fieldName="loyaltyPopup.buttonLink"
                      error={!!errors.loyaltyPopup?.buttonLink}
                      message={errors.loyaltyPopup?.buttonLink?.message}
                      copyToNotificationDeepLink={value =>
                        setValue("loyaltyPopup.buttonLink", value)
                      }
                    />
                  </>
                )}
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name="loyaltyPopup.features"
                  key={`loyaltyPopup.features-${popupType}`}
                  render={({ field: { value, onChange } }) =>
                    !ordering ? (
                      <>
                        <BJList
                          hideRecordCount
                          title={"Features"}
                          hideSearch
                          filterOnlyDisplayList
                          OriginalList={value}
                          columns={columnSchema}
                          onClickRow={item => ({
                            onClick: () => {
                              setSelectedFeatureIndex(
                                value.findIndex(v => v.id === item.id)
                              ),
                                setShowFeatureModal(true);
                            },
                          })}
                          addButtonCaption={"New feature"}
                          onclick={() => {
                            setShowFeatureModal(true);
                          }}
                          onChangeOrder={() => setOrdering(true)}
                        />
                        <LevelFeatureModal
                          show={showFeatureModal}
                          onHide={() => {
                            setShowFeatureModal(false);
                            setSelectedFeatureIndex(undefined);
                          }}
                          onDelete={() => {
                            const newValue = [...value];
                            newValue.splice(selectedFeatureIndex, 1);
                            onChange(newValue);
                          }}
                          onSubmit={feature => {
                            if (selectedFeatureIndex === undefined) {
                              onChange([...value, feature]);
                            } else {
                              const newValue = [...value];
                              newValue[selectedFeatureIndex] = feature;
                              onChange(newValue);
                            }
                          }}
                          levelShortcutFeature={
                            value && value[selectedFeatureIndex]
                          }
                        />
                      </>
                    ) : (
                      <ChangeFeaturesOrderList
                        initialFeatures={value}
                        onCancel={() => setOrdering(false)}
                        onSave={onChange}
                      />
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </FormEdit>
  );
};
