import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChecklists, useCountry } from "../../context";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { changeorder, newRoute } from "../../routes";
import { DeepLinkForList } from "../../components/theme";
import { DeepLinkType, capitalizeFirstLetter } from "../../utils";
import { CheckListType } from "../../utils";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";

type datasourceType = { deepLink?: DeepLinkProps } & Checklist;
export const ChecklistListPage = () => {
  const navigate = useNavigate();
  const { checklists, loading } = useChecklists();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const { t } = useTranslation();
  const { currentCountry, primaryLocale } = useCountry();

  useEffect(() => {
    const offerTableSource: datasourceType[] = checklists.map(checkList => ({
      ...checkList,
      types:
        checkList.types &&
        checkList.types.includes(CheckListType.gettingStarted)
          ? t("checklist:gettingStarted")
          : checkList?.types?.join(", ") ?? checkList.type,
      key: checkList.id,
      deepLink: {
        type: DeepLinkType.Checklist,
        id: checkList?.id,
        countryCode: currentCountry?.abb,
      },
      ...Object.entries(checkList.translations).reduce(
        (acc, [locale, values]) => (
          (acc[`name${capitalizeFirstLetter(locale)}`] = values.name), acc
        ),
        {} as any
      ),
    }));
    setOriginalTable(offerTableSource);
  }, [checklists, currentCountry?.abb, t]);

  const handleNewCheckList = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Name (${locale?.label})`,
        dataIndex: `name${capitalizeFirstLetter(locale?.key)}`,
        key: `name${capitalizeFirstLetter(locale?.key)}`,
        width: 2,
        ellipsis: true,
        onFilter: (value: string | number | boolean, record: datasourceType) =>
          record.translations?.[locale.key]?.name.includes(value as string),
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),

    {
      title: "Types",
      dataIndex: "types",
      key: "types",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    DeepLinkForList(),
  ];

  const downloadData = () => {
    const csvData = [["Id", "Name", "Intro", "Item"]];
    if (!isEmpty(checklists)) {
      checklists?.forEach(t => {
        const name = t?.translations?.[primaryLocale?.key]?.name ?? "";
        const intro = t?.translations?.[primaryLocale?.key]?.intro ?? "";

        csvData.push([
          t.id.toString(),
          name?.replace(/"/g, '""'),
          intro?.replace(/"/g, '""'),
          "",
        ]);

        t?.tasks?.forEach(itm => {
          const text = itm?.translations?.[primaryLocale?.key]?.text ?? "";
          csvData.push([
            t.id.toString(),
            name?.replace(/"/g, '""'),
            intro?.replace(/"/g, '""'),
            text?.replace(/"/g, '""'),
          ]);
        });
      });
    }
    return csvData;
  };

  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New checklist"}
      title={"Check List"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewCheckList}
      recordCountSuffix={"name"}
      downloadData={downloadData()}
      onChangeOrder={() => navigate(changeorder)}
    />
  );
};
