import { Col, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterButton } from "../../components";
import { BJContainer } from "../../components/theme";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { useCountry, useMilestones } from "../../context";
import { newRoute } from "../../routes";
import {
  DisplayUnitType,
  MilestonesType,
  capitalizeFirstLetter,
  downloadCSV,
} from "../../utils";
import _ from "lodash";

type datasourceType = Milestone;

interface MilestoneListPageProps {
  type: MilestonesType;
}

const defaultFilter = DisplayUnitType.Week;

export const MilestoneListPage = ({ type }: MilestoneListPageProps) => {
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<string>(defaultFilter);
  const location = useLocation();
  const { primaryLocale } = useCountry();
  const isChildPage = location.pathname.includes(MilestonesType.Child);
  const [periodFilter, setPeriodFilter] = useState<IFilter>({
    data: [
      {
        text: "Week",
        value: DisplayUnitType.Week,
      },
      {
        text: "Month",
        value: DisplayUnitType.Month,
      },
    ],
    selected: new Set(),
  });
  const {
    milestonesPregnancy,
    milestonesChildWeek,
    milestonesChildMonth,
    loadingMilestonesPregnancy,
    loadingMilestonesChildWeek,
    loadingMilestonesChildMonth,
  } = useMilestones();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const isMonthFilter: boolean = filterType !== defaultFilter;

  useEffect(() => {
    setFilterType(DisplayUnitType.Week);
  }, [type]);
  useEffect(() => {
    if (
      (type === MilestonesType.Child &&
        (loadingMilestonesChildWeek || loadingMilestonesChildMonth)) ||
      (type === MilestonesType.Pregnancy && loadingMilestonesPregnancy)
    ) {
      return;
    }

    const getMilestonesByType = () =>
      (type === MilestonesType.Child && filterType === defaultFilter
        ? milestonesChildWeek
        : type === MilestonesType.Child && isMonthFilter
        ? milestonesChildMonth
        : milestonesPregnancy) || [];

    const mileStoneTableSource: datasourceType[] = getMilestonesByType().map(
      milestone => ({
        ...milestone,
        ...Object.entries(milestone?.translations ?? {}).reduce(
          (acc, [locale, values]) => (
            (acc[`text${capitalizeFirstLetter(locale)}`] = values.text), acc
          ),
          {} as any
        ),
        key: milestone.id,
      })
    );
    setOriginalTable(mileStoneTableSource);
  }, [
    milestonesPregnancy,
    milestonesChildWeek,
    milestonesChildMonth,
    loadingMilestonesPregnancy,
    loadingMilestonesChildWeek,
    loadingMilestonesChildMonth,
    type,
    filterType,
    isMonthFilter,
  ]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        type === MilestonesType.Child
          ? navigate(`${record.id}?type=${filterType}`)
          : navigate(record.id);
      },
    };
  };

  const getLoading = () =>
    type === MilestonesType.Child
      ? loadingMilestonesChildWeek || loadingMilestonesChildMonth
      : loadingMilestonesPregnancy;

  const handleNewMileStone = () => {
    navigate(newRoute);
  };

  const getMilestonesByType = () =>
    (type === MilestonesType.Child && filterType === defaultFilter
      ? milestonesChildWeek
      : type === MilestonesType.Child && isMonthFilter
      ? milestonesChildMonth
      : milestonesPregnancy) || [];

  // PLEASE KEEP THIS COMMENTED OUT CODE FOR FUTURE REFERENCE
  // const downloadData = () => {
  //   const filter = isMonthFilter ? "Month" : "Week";
  //   const csvData = [["Id", "Text", filter]];

  //   _.sortBy(getMilestonesByType(), [
  //     function (o) {
  //       return o.week;
  //     },
  //   ]).forEach(t => {
  //     csvData.push([
  //       t.id.toString(),
  //       t.translations?.[primaryLocale?.key]?.text.replace(/"/g, '""'),
  //       !isMonthFilter ? t.week?.toString() : t.month?.toString(),
  //     ]);
  //   });
  //   return csvData;
  // };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: `Text (${primaryLocale?.label})`,
      dataIndex: `text${capitalizeFirstLetter(primaryLocale?.key)}`,
      key: `text${capitalizeFirstLetter(primaryLocale?.key)}`,
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: isMonthFilter ? "Month" : "Week",
      dataIndex: isMonthFilter ? DisplayUnitType.Month : DisplayUnitType.Week,
      key: isMonthFilter ? DisplayUnitType.Month : DisplayUnitType.Week,
      width: 1,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  const onChangeFilter = (value: string) => {
    setFilterType(value);
    setPeriodFilter(prev => {
      const selected = prev.selected;
      if (selected.has(value)) {
        selected.delete(value);
      } else {
        selected.add(value);
      }
      return {
        data: prev.data,
        selected: selected,
      };
    });
  };

  const renderListFilters = () => {
    if (!isChildPage) {
      return;
    } else {
      return (
        <BJContainer>
          <Row wrap gutter={[20, 20]}>
            <Col>
              <FilterButton
                label="Filter By:"
                filter={periodFilter}
                onPressItem={item => {
                  onChangeFilter(item);
                }}
                onCheckAll={() => console.log("onCheckAll")}
                disableCheckAll
                single
                defaultValue={periodFilter.data[0].text}
              />
            </Col>
          </Row>
        </BJContainer>
      );
    }
  };

  return (
    <BJList
      loading={getLoading()}
      filterOnlyDisplayList
      addButtonCaption={"New Milestone"}
      title={"Milestones"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewMileStone}
      recordCountSuffix={"Milestones"}
      defaultPageSize={10}
      showSizeChanger
      filters={renderListFilters()}
      downloadData={downloadCSV({
        topic: "milestones",
        isMonthFilter,
        getMilestonesByType,
        primaryLocale,
      })}
      isUpload
      topic="milestones"
    />
  );
};
