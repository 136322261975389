import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCountry, useDietaryAdvice, useDietaryAdvices } from "../../context";
import { FormEdit, FormEditType } from "../../components";
import { commonErrors } from "../../language";
import {
  ContentTypeNames,
  DeepLinkType,
  formValidationError,
} from "../../utils";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJSelectFormItem,
  BJSwitchFormItem,
  BJTagsFormItem,
} from "../../components/theme";

const indicators: { key: string; value: string; display: string }[] = [
  {
    key: "ok1k",
    value: "OK",
    display: "Ok",
  },
  {
    key: "avoid2d",
    value: "AVOID",
    display: "Avoid",
  },
  {
    key: "warning3g",
    value: "WARNING",
    display: "Warning",
  },
];

type FormValues = {
  categoryId: string;
  name: string;
  indicator: "OK" | "AVOID" | "WARNING";
  promoted: boolean;
  translations: {
    [locale: string]: {
      name: string;
      description: string;
      tagWords: string[];
    };
  };
};
const { requiredError } = commonErrors;

export const DietaryAdvicePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { dietaryAdvice, loading } = useDietaryAdvice(id);
  const { currentCountry, primaryLocale } = useCountry();
  const {
    categories,
    createDietaryAdvice,
    updateDietaryAdvice,
    deleteDietaryAdvice,
  } = useDietaryAdvices();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          name: yup
            .string()
            .required(
              `Name (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
    categoryId: yup.string().required(`Category: ${requiredError}`),
    indicator: yup.string().required(`Indicator: ${requiredError}`),
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const translations = watch("translations");

  useEffect(() => {
    if (loading || dietaryAdvice === null) {
      return;
    }
    reset({
      ...dietaryAdvice,
    });
  }, [dietaryAdvice, loading, reset]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const translations: DietaryAdviceV2["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        name: value.name,
        description: value.description,
        tagWords: value.tagWords,
      };
    }

    const updated: Omit<DietaryAdviceV2, "id"> = {
      categoryId: data.categoryId,
      indicator: data.indicator,
      importId: "",
      promoted: data.promoted ?? false,
      translations,
    };
    if (dietaryAdvice) {
      await updateDietaryAdvice(dietaryAdvice.id, updated);
    } else {
      const { id } = await createDietaryAdvice(updated);
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (dietaryAdvice) {
      await deleteDietaryAdvice(dietaryAdvice.id);
      return navigate("./..", { replace: true });
    } else {
      throw new Error("Record not found");
    }
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <FormEdit
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={
        dietaryAdvice
          ? dietaryAdvice.translations?.[primaryLocale.key]?.name
          : "New Dietary Advice"
      }
      id={dietaryAdvice?.id}
      editType={dietaryAdvice?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={dietaryAdvice?.id}
      deepLink={{
        type: DeepLinkType.Dietary,
        id: dietaryAdvice?.id,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <Row className="mt-4" gutter={{ md: 20 }}>
          <Col md={24} lg={12}>
            <BJInputFormItem
              label={`Name (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.name`}
              key={`translations.${locale.key}.name`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.name}
              message={errors?.translations?.[locale.key]?.name?.message}
              required
            />

            <BJMdFormItem
              fieldName={`translations.${locale.key}.description`}
              key={`translations.${locale.key}.description`}
              label={`Description (${locale?.label ?? ""})`}
              control={control}
              required={false}
            />

            <BJSwitchFormItem
              label="Promote"
              fieldName={"promoted"}
              control={control}
            />
          </Col>
          <Col md={24} lg={12}>
            <BJSelectFormItem
              label={"Category"}
              fieldName={"categoryId"}
              size="large"
              control={control}
              error={!!errors?.categoryId}
              message={errors?.categoryId?.message}
              optionsList={categories?.map(c => ({
                value: c.id,
                key: c.id,
                display: c.translations[locale.key]?.title,
              }))}
              required
              includeEmpty
            />

            <BJSelectFormItem
              label={"Indicator"}
              fieldName={"indicator"}
              size="large"
              control={control}
              error={!!errors?.indicator}
              message={errors?.indicator?.message}
              optionsList={indicators?.map(i => ({ ...i }))}
              required
              includeEmpty
            />

            <BJTagsFormItem
              label={`Tag Words (${locale?.label ?? ""})`}
              key={`translations.${locale.key}.tagWords`}
              fieldName={`translations.${locale.key}.tagWords`}
              control={control}
              setValue={setValue}
              aiTagWordsPayload={{
                contentTitle: translations?.[locale.key]?.name,
                contentBody: translations?.[locale.key]?.description,
                contentType: ContentTypeNames.dietaryAdvices,
                documentId: dietaryAdvice?.id,
                language: locale.label,
              }}
              existingTags={translations?.[locale.key]?.tagWords || []}
              locale={locale.key}
            />
          </Col>
        </Row>
      )}
    </FormEdit>
  );
};
