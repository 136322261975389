import { Typography } from "antd";
import { useMemo, useState } from "react";
import { FaCopy } from "react-icons/fa";
import styled from "styled-components";
import {
  createDeepLink,
  createDynamicLink,
  getDeepLinkPrefix,
  createOneLink,
} from "../../../pages/DeepLink/methods";

import { DeeplinkCopyModal } from "../../DeeplinkCopyModal";
import { IconWrapper } from "../../styled/BJCommonStyles";
import { useCountry } from "../../../context";
import { OneLinkTypes } from "../../../utils";

const { Text } = Typography;

const BJDeeplink = ({
  type,
  id,
  displayHeader: displayText = true,
  displayLinkText = false,
  params,
  builtDeeplink,
}: DeepLinkProps) => {
  const [copyModalVisible, showCopyModal] = useState(false);
  const { currentCountry } = useCountry();
  const deepLinkPrefix = getDeepLinkPrefix();
  const oneLinkRenderTypes = Object.values(OneLinkTypes);

  const deepLink = useMemo(() => {
    return builtDeeplink
      ? builtDeeplink
      : createDeepLink({
          type,
          id,
          params,
          countryCode: currentCountry?.abb,
        });
  }, [type, id, params, currentCountry?.abb, builtDeeplink]);

  const getOneLink = (type: OneLinkTypes, link: string) => {
    const path = link?.replace(`${deepLinkPrefix.domain}/`, "");
    return createOneLink(path, type);
  };
  const oneLinks = oneLinkRenderTypes.reduce<{
    [key in OneLinkTypes]?: string;
  }>((acc, type) => {
    acc[type] = getOneLink(type, deepLink);
    return acc;
  }, {});

  const dynamicLink = createDynamicLink(deepLink);

  const onClickCopy = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    showCopyModal(true);
  };

  const onCloseModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    showCopyModal(false);
  };

  return (
    <StyledDiv>
      <DeeplinkCopyModal
        show={copyModalVisible}
        deepLink={deepLink}
        dynamicLink={dynamicLink}
        oneLinks={oneLinks}
        onHide={onCloseModal}
      />
      <StyledDeepLink>
        {displayText && <StyledText type="secondary">DeepLink : </StyledText>}
        {displayLinkText && (
          <StyledLink href={deepLink} target="_blank" rel="noreferrer">
            {deepLink}
          </StyledLink>
        )}
        <IconWrapper disabled={false} hoverEffect onClick={onClickCopy}>
          <FaCopy size={20} />
        </IconWrapper>
      </StyledDeepLink>
    </StyledDiv>
  );
};

const DeepLinkForList = () => {
  const deepLink = {
    title: "Deep link",
    dataIndex: "deepLink",
    key: "deepLink",
    width: 0.5,
    ellipsis: true,
    render: (value: DeepLinkProps) => (
      <BJDeeplink {...value} displayHeader={false} />
    ),
  };
  return deepLink;
};

const StyledDeepLink = styled.div`
  display: flex;
`;
const StyledLink = styled.a`
  margin: 0 5px 0 0;
`;
const StyledDiv = styled.div`
  margin: 0 0 5px 0;
`;

const StyledText = styled(Text)`
  margin: 0 5px 0 0;
  overflow: elipsis;
`;

export { BJDeeplink, DeepLinkForList };
