import { useForm } from "react-hook-form";
import { FormModal } from "../../../components";
import { useCountry } from "../../../context";
import { formValidationError } from "../../../utils";
import { BJInputFormItem } from "../../../components/theme";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import _ from "lodash";
import { BJDeeplinkFormInput } from "../../../components/theme/molecules/formItems/BJDeeplinkFormInput";

type Props = {
  onHide: () => void;
  show: boolean;
  onDelete: () => void;
  onSubmit: (data: LevelFeature) => void;
  levelShortcutFeature?: LevelFeature;
};

type FormState = Omit<LevelFeature, "id">;

export const LevelFeatureModal = ({
  onHide,
  show,
  onDelete,
  levelShortcutFeature,
  onSubmit,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
    setValue,
  } = useForm<FormState>({
    defaultValues: {
      translations: {},
    },
  });
  const { primaryLocale } = useCountry();

  const onSubmitFeature = (data: FormState) => {
    onSubmit({
      ...data,
      id: levelShortcutFeature?.id ?? uuid(),
    });
  };
  useEffect(() => {
    reset({
      translations: _.cloneDeep(levelShortcutFeature?.translations) ?? {},
      deepLink: levelShortcutFeature?.deepLink,
    });
  }, [levelShortcutFeature, show]);

  return (
    <FormModal
      onHide={onHide}
      key={"levelFeature"}
      formId={"levelFeature"}
      enableSave={isDirty}
      enableDelete={!!levelShortcutFeature}
      show={show}
      onSubmit={handleSubmit(onSubmitFeature, formValidationError)}
      onDelete={onDelete as () => Promise<void>}
      modalTitle={
        levelShortcutFeature?.translations?.[primaryLocale?.key] ??
        "Add feature"
      }
      recordIdentifier={levelShortcutFeature?.id}
      errors={errors as any}
      localeSupported
    >
      {locale => (
        <>
          <BJInputFormItem
            label={`Feature title (${locale?.label})`}
            control={control}
            fieldName={`translations.${locale?.key}`}
            key={`translations.${locale?.key}`}
          />
          <BJDeeplinkFormInput
            label={"Deep link"}
            title="Level feature"
            control={control}
            fieldName="deepLink"
            error={!!errors.deepLink}
            message={errors.deepLink?.message}
            copyToNotificationDeepLink={value => setValue("deepLink", value)}
          />
        </>
      )}
    </FormModal>
  );
};
