import { useEffect, useState } from "react";
import { Form } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PodcastEpisodeMessages } from "../../../language";
import { BJTagsInput, FormModal } from "../../../components";
import { useCountry, usePodcast } from "../../../context";
import {
  capitalizeFirstLetter,
  ContentTypeNames,
  formValidationError,
  getFormattedErrorMessage,
  getMax,
} from "../../../utils";
import { BJInputFormItem, BJTagsFormItem } from "../../../components/theme";

interface Props {
  show: boolean;
  season: PodcastSeason | null;
  podcastId: FullPodcast["id"];
  onHide: () => void;
}

type FormValues = {
  translations: {
    [key: string]: {
      title: string;
      description: string;
      tagWords: string[];
    };
  };
};

export const SeasonModal = ({ show, season, podcastId, onHide }: Props) => {
  const { currentCountry } = useCountry();
  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${item.key.toUpperCase()}): ${
                PodcastEpisodeMessages.requiredValidationText
              }`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { seasons, addSeason, updateSeason, deleteSeason } =
    usePodcast(podcastId);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (season) {
      reset({
        ...season,
      });
    } else {
      reset({});
    }
    setError(null);
  }, [season, reset, show]);

  const translations = watch("translations");

  const onSubmit: SubmitHandler<FormValues> = async data => {
    try {
      const { translations } = data;
      if (season) {
        await updateSeason(season.id, {
          podcastId: podcastId,
          sortOrder: season.sortOrder,
          translations,
        });
      } else {
        let highestSortOrder: number = getMax(seasons, "sortOrder");
        await addSeason({
          podcastId: podcastId,
          sortOrder: highestSortOrder++,
          translations,
        });
      }
      onHide();
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const onDelete = async () => {
    if (!season) {
      return;
    }
    try {
      await deleteSeason(season.id);
      onHide();
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  return (
    <FormModal
      messageOnSubmit={false}
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      errors={errors as any[]}
      onDelete={onDelete}
      enableDelete={!!season}
      modalSubTitle={season ? `Id - ${season?.id}` : ""}
      modalTitle={season ? "Update season" : "Add season"}
      size={"lg"}
      localeSupported
    >
      {locale => (
        <>
          <BJInputFormItem
            required
            {...register(`translations.${locale?.key}.title`)}
            control={control}
            error={!!errors.translations?.[locale?.key]?.title?.message}
            label={`Title (${capitalizeFirstLetter(locale?.key)})`}
            message={errors.translations?.[locale?.key]?.title?.message}
            fieldName={`translations.${locale?.key}.title`}
            key={`translations.${locale?.key}.title`}
          />
          <BJInputFormItem
            control={control}
            error={!!errors.translations?.[locale?.key]?.description?.message}
            label={`Description (${capitalizeFirstLetter(locale?.key)})`}
            rows={6}
            message={errors.translations?.[locale?.key]?.description?.message}
            fieldName={`translations.${locale?.key}.description`}
            key={`translations.${locale?.key}.description`}
            {...register(`translations.${locale?.key}.description`)}
          />
          <BJTagsFormItem
            control={control}
            fieldName={`translations.${locale?.key}.tagWords`}
            label={`Tag words (${capitalizeFirstLetter(locale?.key)})`}
            existingTags={translations?.[locale.key]?.tagWords || []}
            locale={locale.key}
            setValue={setValue}
            aiTagWordsPayload={{
              contentTitle: translations?.[locale.key]?.title,
              contentBody: translations?.[locale.key]?.description,
              contentType: ContentTypeNames.seasons,
              documentId: season?.id,
              language: locale.label,
            }}
          />
        </>
      )}
    </FormModal>
  );
};
