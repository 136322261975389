import styled from "styled-components";
import { Layout } from "antd";
import SiderNavigationBar from "../components/theme/components/SiderNavBar";
import { Content } from "antd/lib/layout/layout";
import { BJContainer } from "../components/theme/atoms/BJContainer";
import { FaPodcast, FaUpload } from "react-icons/fa";
import { HiOutlineUserAdd } from "react-icons/hi";
import { Outlet } from "react-router";
import { ErrorFallback } from "../components";
import { useNavigate } from "react-router-dom";
import { GrGamepad } from "react-icons/gr";
import * as Sentry from "@sentry/react";
import { GiTatteredBanner } from "react-icons/gi";
import { Offers } from "../assets/icons/icons";
import { BiWorld } from "react-icons/bi";
import { SlEnvolopeLetter } from "react-icons/sl";

export const adminMenuItemList: MenuItemInfo[] = [
  { displayName: "Mobile users", url: `users`, icon: <FaPodcast /> },
  {
    displayName: "Mini Journeys",
    url: `minijourneys`,
    icon: <GiTatteredBanner />,
  },
  {
    displayName: "Competitions",
    url: `competitions`,
    icon: <GrGamepad />,
    regionSupport: true,
  },
  { displayName: "Force Update", url: `force-update`, icon: <FaUpload /> },
  {
    displayName: "Offer Signups",
    url: `signupoffer`,
    icon: <Offers />,
    regionSupport: true,
  },
  {
    displayName: "Country And Locales",
    url: `country-locales`,
    icon: <BiWorld />,
  },
  {
    displayName: "Backoffice users",
    url: `backoffice-users`,
    icon: <HiOutlineUserAdd />,
  },
  {
    displayName: "Birth Stories",
    url: `birth-stories`,
    icon: <SlEnvolopeLetter />,
  },
];

export const AdminLayout = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <StyledLayout>
        <SiderNavigationBar items={adminMenuItemList} />
        <StyledLayoutContent>
          <Content>
            <BJContainer>
              <Sentry.ErrorBoundary
                beforeCapture={scope => {
                  scope.setTag("section", "Admin");
                }}
                fallback={ErrorFallback}
                onReset={() => {
                  navigate("./");
                }}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </BJContainer>
          </Content>
        </StyledLayoutContent>
      </StyledLayout>
      {/* <StyledFooter>Baby Journey ©2021 Created by Baby Journey</StyledFooter> */}
    </Layout>
  );
};

const StyledLayout = styled(Layout)`
  background-color: ${props => props.theme.white};
  height: 100vh;
`;

const StyledLayoutContent = styled(Layout)`
  padding: 1rem 1rem 1rem;
`;
//TODO footer is commented until find a fixed solution
// const StyledFooter = styled(Footer)`
//   text-align: center;
//   background-color: ${props => props.theme.primary};
//   padding: 1rem;
//   /* position: sticky;
//   bottom: 0;
//   z-index: 999; */
// `;
