import { Typography, Switch, Card, List } from "antd";

export type Partner = {
  userId: string;
};

type SharedAccountProps = {
  partners?: Partner[];
  partnerId: string;
};

export const SharedAccountInformation = ({
  partners,
  partnerId,
}: SharedAccountProps) => {
  return (
    <Card title="Has shared account with" bordered={false}>
      <Switch checked={(partners && partners.length > 0) || !!partnerId} />
      {partners?.length > 0 ? (
        <List
          size="large"
          header={<Typography.Text>Partners List </Typography.Text>}
          dataSource={partners}
          renderItem={item => (
            <List.Item>
              <Typography.Text>{item.userId} </Typography.Text>
            </List.Item>
          )}
        />
      ) : (
        partnerId && (
          <List
            size="large"
            header={<Typography.Text>Partner</Typography.Text>}
            dataSource={[partnerId]}
            renderItem={item => (
              <List.Item>
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            )}
          />
        )
      )}
    </Card>
  );
};
