import { useState } from "react";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Typography, Layout, Space, Divider, Form } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { UserService } from "../../../services";
import { commonErrors, commonSuccessMessages } from "../../../language";
import {
  formatDate,
  formatStringDate,
  generateServerError,
} from "../../../utils";
import { logException } from "../../../utils/exceptionLogger";
import { NotificationType } from "../../../components/theme/util/notificationObj";

import { type DisplayUser, UserInformation } from "./UserInformation";
import { type DisplayChildren, ChildInformation } from "./ChildrenInformation";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { CenteredSpinner, ConfirmationModal } from "../../../components";
import { BJNotification } from "../../../components/theme/atoms/BJNotification";
import BJInput from "../../../components/theme/atoms/BJInput";
import { PregnancyInformation } from "./PregnancyInformation";
import {
  type Partner,
  SharedAccountInformation,
} from "./SharedAccountInformation";
import { type OtherProps, OtherInformation } from "./OtherInformation";
import {
  type BirthPlanProps,
  BirthPlansInformation,
} from "./BirthPlansInformation";
import {
  type NotificationSettingsProps,
  NotificationInformation,
} from "./NotificationInformation";
import { getZodiacSign } from "../../../helper/child";

const { requiredError, deleteRecordError, searchRecordError } = commonErrors;

const schema = yup.object().shape({
  searchText: yup.string().required(requiredError),
});

type FormValues = {
  searchText: string;
  reason?: string;
};

type UserProps = {
  user: (DisplayUser & OtherProps & NotificationSettingsProps) | null;
  children?: DisplayChildren[];
  partners?: Partner[];
  birthplans?: BirthPlanProps[];
  pregnancies?: Pregnancy[];
};

const UserSearchPage = () => {
  const [userResponse, setUserResponse] = useState<UserProps>({ user: null });
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSearch = async (data: FormValues) => {
    setIsSearching(true);
    setHasSearched(true);
    try {
      const response = await UserService.getUserByUserIdOrEmail(
        data.searchText
      );
      if (response) {
        const formattedResponse = mapUserResponse(response);
        setUserResponse(formattedResponse);
      }
    } catch (error) {
      logException(error);
      const serverErrorMessage = generateServerError(error);
      setUserResponse({ user: null });
      BJNotification({
        type: NotificationType.Error,
        message: searchRecordError,
        description: serverErrorMessage,
      });
    } finally {
      setIsSearching(false);
    }
  };

  const onDelete = async () => {
    const reasonForDelete = getValues("reason") ?? "";
    const id = userResponse?.user?.id;
    setIsDeleting(true);
    setShowDeleteModal(false);
    try {
      await UserService.deleteUser(id!, "admin deletes user", reasonForDelete);
      BJNotification({
        type: NotificationType.Success,
        message: commonSuccessMessages.deleteSuccessMessageHeader,
        description: commonSuccessMessages.deleteSuccessMessage,
      });
      const mappedResponse = mapUserResponse({ user: null });
      reset({ reason: "", searchText: "" });
      setUserResponse(mappedResponse);
      setIsDeleting(false);
    } catch (error) {
      const serverErrorMessage = generateServerError(error);
      setUserResponse({ user: null });
      BJNotification({
        type: NotificationType.Error,
        message: deleteRecordError,
        description: serverErrorMessage,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapUserResponse = (responseData: any) => {
    const user: UserProps["user"] =
      !responseData.user && !responseData.authUser
        ? null
        : {
            ...responseData.user,
            ...responseData.authUser,
            creationTime: responseData.authUser?.metadata?.creationTime,
            dateOfBirth: formatDate(responseData.user?.dateOfBirth),
          };

    const children: DisplayChildren[] =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseData?.children?.map((child: any) => {
        const genderTranslation = ["Not selected", "Girl", "Boy"];

        return {
          ...child,
          gender: genderTranslation[child.gender],
          dateOfBirth: moment(child.dateOfBirthString).format("D MMM YYYY"),
          zodiacSign: getZodiacSign(new Date(child.dateOfBirthString)),
        };
      }) ?? [];

    return {
      ...responseData,
      user,
      birthplans: (responseData.birthplans || [])
        .filter((birthplan: { pregnancyId: string }) => {
          const pregnancyInfo = (responseData.pregnancies || []).find(
            (pregnancy: Pregnancy) => pregnancy.id === birthplan.pregnancyId
          );

          return Boolean(pregnancyInfo?.isActive);
        })
        .map(
          (birthplan: {
            id: string;
            personalInfo: Record<
              string,
              string | Record<string, string | boolean>
            >[];
          }) => ({
            user: {
              email: user.email,
              uid: user.id,
              locale: user.locale,
              country: user.country,
            },
            docId: birthplan.id,
            ...birthplan.personalInfo.reduce((acc, curr) => {
              if ("estimatedDueDate" in curr) {
                return {
                  ...acc,
                  estimatedDueDate: formatStringDate(
                    curr.estimatedDueDate as string
                  ),
                };
              }

              return { ...acc, ...curr };
            }, {}),
          })
        ),
      pregnancies: responseData.pregnancies,
      children,
      partnersList: responseData.partners,
    };
  };

  const { user, children, birthplans, partners, pregnancies } = userResponse;

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        text={commonErrors.onRemoveContentError}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
        additionalContent={() => (
          <Form.Item label="Reason">
            <Controller
              control={control}
              name="reason"
              render={({ field }) => <BJInput autoFocus {...field} />}
            />
          </Form.Item>
        )}
      />
      <Layout>
        <Form name="Basic" layout="vertical" onFinish={handleSubmit(onSearch)}>
          <Typography.Title level={2}>Change user information</Typography.Title>
          <Row gutter={[12, 20]}>
            <Col span={10} offset={4}>
              <Form.Item
                label=""
                name="searchText"
                validateStatus={errors.searchText && "error"}
                extra={
                  <Typography.Paragraph type="danger">
                    {errors.searchText?.message}
                  </Typography.Paragraph>
                }
                required
              >
                <Controller
                  control={control}
                  name="searchText"
                  render={({ field }) => (
                    <BJInput
                      placeholder="Enter user id or email here"
                      {...field}
                      autoFocus
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Space>
                <BJButton
                  buttonType={ButtonTypes.Save}
                  disabled={isSearching}
                  loading={isSearching}
                  size="large"
                  htmlType="submit"
                >
                  Search
                </BJButton>
                <BJButton
                  buttonType={ButtonTypes.Delete}
                  disabled={isSearching || !user}
                  loading={isDeleting}
                  size="large"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </BJButton>
              </Space>
            </Col>
          </Row>
        </Form>
        {isSearching ? (
          <CenteredSpinner />
        ) : user ? (
          <>
            <Divider />
            <UserInformation user={user} />
            <PregnancyInformation pregnancies={pregnancies} />
            <ChildInformation>{children}</ChildInformation>
            <BirthPlansInformation birthplans={birthplans} />
            <NotificationInformation
              notificationSettings={user.notificationSettings}
              fcm={user.fcm}
            />
            <SharedAccountInformation
              partnerId={user.partnerId}
              partners={partners}
            />
            <OtherInformation {...user} />
          </>
        ) : (
          hasSearched && (
            <Row>
              <Divider />
              <Col offset={5}>
                <Typography.Title type="danger">
                  No records available
                </Typography.Title>
              </Col>
            </Row>
          )
        )}
      </Layout>
    </>
  );
};

export default UserSearchPage;
