import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

type InfluencerData = {
  name: string;
  categories: string[];
};

export class InfluencerService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  static toInfluencer = (doc: any): Influencer => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
    };
  };

  subscribe = (
    callback: (err: Error | null, influencers: Influencer[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Influencers[this.countryCode])
      .onSnapshot(
        snapshot => {
          const influencers = snapshot.docs.map(InfluencerService.toInfluencer);
          callback(null, influencers);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: InfluencerData) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Influencers[this.countryCode])
      .add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  update = (id: string, data: InfluencerData) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Influencers[this.countryCode])
      .doc(id)
      .update({
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Influencers[this.countryCode])
      .doc(id)
      .delete();
}
