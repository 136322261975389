import { Form, Modal, Typography } from "antd";
import {
  Control,
  Controller,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
} from "react-hook-form";
import { BJTagsInput } from "../../atoms/BJTagsInput";
import BJButton, { ButtonTypes } from "../../atoms/Button";
import { TagsService } from "../../../../services";
import { useEffect, useState } from "react";
import { BJFlex } from "../../../styled";
import { BJFormattedTitle } from "../../components/BJFormattedTitle";
import styled from "styled-components";

interface Tag {
  isAiGenerated: boolean;
  value: string;
}

const TagContainer = styled.div<{ isAiGenerated: boolean }>`
  border-width: 1px;
  border-color: ${({ theme }) => theme.stroke};
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${({ isAiGenerated, theme }) =>
    isAiGenerated ? theme.button.secondary : "transparent"};
`;

const DangerText = styled.span`
  color: ${({ theme }) => theme.text.danger};
`;

export const BJTagsFormItem = <T,>({
  error,
  message,
  label,
  required,
  control,
  fieldName,
  disabled,
  extra,
  setValue,
  aiTagWordsPayload,
  existingTags = [],
  locale,
}: {
  error?: boolean;
  message?: string;
  label: string;
  required?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
  existingTags?: string[] | undefined;
  autoFocus?: boolean;
  disabled?: boolean;
  extra?: string;
  type?: string;
  setValue?: UseFormSetValue<T>;
  aiTagWordsPayload?: GenerateTagsPayload;
  locale: string;
}) => {
  const [generatingTagwords, setGeneratingTagwords] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [generatedTags, setGeneratedTags] = useState<Tag[]>([]);

  const mapExistingTags = (existingTags: string[], aiGenerated: boolean) => {
    return existingTags.map(tag => ({
      isAiGenerated: aiGenerated,
      value: tag,
    }));
  };

  const onGenerate = async () => {
    setGeneratingTagwords(true);
    const aiTagWords = await TagsService.generateTags(aiTagWordsPayload);
    setGeneratingTagwords(false);
    setSelectedTags(prev => [
      ...prev,
      ...aiTagWords.map(tag => ({ isAiGenerated: true, value: tag })),
    ]);
  };

  const onSelectTag = (tag: Tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
      setGeneratedTags([...generatedTags, tag]);
    } else {
      setSelectedTags([...selectedTags, tag]);
      setGeneratedTags(generatedTags.filter(t => t !== tag));
    }
  };

  const onSave = () => {
    // Get the values of the selected tags
    const selectedTagValues = selectedTags.map(tag => tag.value);

    // Update the form value with only the selected tags
    setValue(
      fieldName,
      selectedTagValues as UnpackNestedValue<PathValue<T, Path<T>>>,
      { shouldDirty: true }
    );

    // Clear selected tags
    setSelectedTags([]);
    setGeneratedTags([]);

    // Close the modal
    setIsModalVisible(false);
  };

  useEffect(() => {
    // Clear selected tags when the language changes
    setSelectedTags([]);
    setGeneratedTags([]);

    // Map existing tags to selected tags based on the current language
    if (existingTags && existingTags.length > 0) {
      setSelectedTags(mapExistingTags(existingTags, false));
    }
  }, [existingTags, locale]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <BJFlex row>
            <BJButton
              loading={generatingTagwords}
              onClick={onGenerate}
              buttonType={ButtonTypes.Secondary}
            >
              Generate
            </BJButton>
            <BJButton buttonType={ButtonTypes.Primary} onClick={() => onSave()}>
              Save
            </BJButton>
          </BJFlex>
        }
      >
        <BJFlex gap={1}>
          <BJFormattedTitle title="Unselected Tags" />
          <DangerText>
            {generatedTags.length > 0 &&
              "Please click on a tag to select it and add it to the list"}
            {generatedTags.length === 0 &&
              selectedTags.length === 0 &&
              "Please click Generate to generate tags"}
          </DangerText>
          <BJFlex row gap={1} wrap>
            {generatedTags.map((tag, index) => (
              <TagContainer
                key={index}
                isAiGenerated={tag.isAiGenerated}
                onClick={() => onSelectTag(tag)}
              >
                <p style={{ marginBottom: 0 }}>{tag.value}</p>
              </TagContainer>
            ))}
          </BJFlex>
        </BJFlex>
        <BJFlex gap={1} mTop={1}>
          <BJFormattedTitle title="Selected Tags" />
          <DangerText>
            {selectedTags.length > 0 && "Click on a tag to unselect it"}
          </DangerText>
          <BJFlex row gap={1} wrap>
            {selectedTags?.map((tag: Tag, index: number) => (
              <TagContainer
                key={index}
                isAiGenerated={tag.isAiGenerated}
                onClick={() => onSelectTag(tag)}
              >
                <p style={{ marginBottom: 0 }}>{tag.value}</p>
              </TagContainer>
            ))}
          </BJFlex>
        </BJFlex>
      </Modal>
      <Form.Item
        label={label}
        name={fieldName}
        required={required}
        validateStatus={error && "error"}
        {...(message
          ? {
              help: (
                <Typography.Paragraph type="danger">
                  {message}
                </Typography.Paragraph>
              ),
            }
          : undefined)}
        {...(extra
          ? {
              extra: (
                <Typography.Paragraph type="warning">
                  {extra}
                </Typography.Paragraph>
              ) as JSX.Element,
            }
          : undefined)}
      >
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { onChange, value } }) => (
            <BJTagsInput tags={value as string[]} onChange={onChange} />
          )}
        />
        {setValue && aiTagWordsPayload && (
          <BJButton
            buttonType={ButtonTypes.Primary}
            loading={generatingTagwords}
            onClick={() => setIsModalVisible(true)}
          >
            Generate with AI
          </BJButton>
        )}
      </Form.Item>
    </>
  );
};
