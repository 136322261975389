import { Alert, Col, Form, Modal, Row, Typography, Radio } from "antd";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { generateFormError } from "../utils";
import { BJFlex } from "./styled";
import { BJDeeplink } from "./theme";
import { BJNotification } from "./theme/atoms/BJNotification";
import BJButton, { ButtonTypes } from "./theme/atoms/Button";
import { NotificationType } from "./theme/util/notificationObj";
import { useCountry } from "../context";
import { FieldError, set } from "react-hook-form";

const { Paragraph } = Typography;

type ModalType = {
  formId?: string;
  messageOnSubmit?: boolean;
  show?: boolean;
  onHide: () => void;
  title?: { titleId: string; titleText: string };
  deleting?: boolean;
  error?: string | null;
  onDelete?: () => Promise<void>;
  enableSave: boolean;
  enableDelete: boolean;
  onSubmit: () => void;
  modalTitle:
    | string
    | {
        [locale: string]: {
          title: string;
        };
      };
  modalSubTitle?: string;
  size?: "sm" | "lg" | "xl" | undefined;
  recordIdentifier?: string;
  deepLink?: DeepLinkProps;
  localeSupported?: boolean;
  errors?: any[];
};

type PropsWithChildren<T> = T & {
  children?: ReactNode | ((language: Locale) => ReactNode);
};

export const FormModal = ({
  formId,
  show,
  onHide,
  onDelete,
  children,
  enableSave,
  enableDelete,
  onSubmit,
  modalTitle: modalTitleProp,
  modalSubTitle,
  recordIdentifier,
  messageOnSubmit = true,
  size,
  deepLink,
  localeSupported,
  errors,
}: PropsWithChildren<ModalType>) => {
  const { currentCountry, primaryLocale } = useCountry();

  const [deleting, setDeleting] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [language, setLanguage] = useState(primaryLocale?.key);

  useEffect(() => {
    if (primaryLocale?.key) {
      setLanguage(primaryLocale?.key);
    }
  }, [primaryLocale?.key]);

  const formErrors = useMemo(() => {
    const errMessages: string[] = [];
    if (!errors) {
      return errMessages;
    }
    for (const [, errorValue] of Object.entries(errors)) {
      const fields = errorValue as
        | FieldError
        | FieldError[]
        | { [country: string]: FieldError }
        | { [country: string]: { [fields: string]: FieldError } };
      if (Array.isArray(fields)) {
        const fieldWithMessage = fields.find(field => field?.message);
        if (fieldWithMessage) {
          errMessages.push(fieldWithMessage.message.toString());
        }
        continue;
      }
      if (fields && fields.message) {
        errMessages.push(fields.message.toString());
      } else {
        for (const fieldValue of Object.values(fields)) {
          if (fieldValue) {
            if (fieldValue.message) {
              errMessages.push(fieldValue.message.toString());
            } else {
              for (const [, nestedValue] of Object.entries(fieldValue)) {
                const innerValue = nestedValue as FieldError;
                if (innerValue && innerValue.message) {
                  errMessages.push(innerValue.message.toString());
                }
              }
            }
          }
        }
      }
    }
    return errMessages;
  }, [errors]);

  const onFinish = async () => {
    try {
      setError(null);
      setSaving(true);
      await onSubmit();
      messageOnSubmit &&
        BJNotification({
          type: NotificationType.Success,
          message: "Update record",
          description: `${recordIdentifier ?? "Record"} updated`,
        });
      setSaving(false);
      onHide();
    } catch (err) {
      const errorMessage = generateFormError(err);
      setError(errorMessage);
      messageOnSubmit &&
        BJNotification({
          type: NotificationType.Error,
          message: " Error",
          description: errorMessage,
        });
    } finally {
      setSaving(false);
    }
  };

  const onDeleteRecord = async () => {
    setDeleting(true);
    try {
      onDelete && (await onDelete());
      messageOnSubmit &&
        BJNotification({
          type: NotificationType.Success,
          message: "Record deleted",
          description: `${recordIdentifier ?? "Record"} Deleted successfully`,
        });
      onHide();
    } catch (err) {
      const errorMessage = generateFormError(err);
      setError(errorMessage);

      messageOnSubmit &&
        BJNotification({
          type: NotificationType.Error,
          message: "Error deleting record",
          description: errorMessage,
        });
    } finally {
      setDeleting(false);
      setDeleteState(false);
    }
  };

  const renderErrors = () => {
    if (formErrors && formErrors?.length) {
      return (
        <BJFlex centerJustify mBottom={1}>
          <Alert
            showIcon
            message="Error"
            description={formErrors.map((err, index) => (
              <div key={`error_alert_${index}`}>{err}</div>
            ))}
            type="error"
          />
        </BJFlex>
      );
    } else if (error) {
      return (
        <BJFlex centerJustify mBottom={1}>
          <Alert showIcon message={error} type="error" />
        </BJFlex>
      );
    } else {
      return;
    }
  };

  const modalTitle =
    typeof modalTitleProp === "object"
      ? modalTitleProp[language]?.title
      : modalTitleProp;

  return (
    <Modal
      {...(size
        ? size === "sm"
          ? { width: "20%" }
          : size === "lg"
          ? { width: "50%" }
          : size === "xl"
          ? { width: "80%" }
          : {}
        : {})}
      destroyOnClose
      title={
        <Col span={24}>
          {modalTitle && <Paragraph ellipsis>{modalTitle}</Paragraph>}
          {modalSubTitle && <Paragraph>{modalSubTitle}</Paragraph>}
          {deepLink ? (
            <div>
              <BJDeeplink {...deepLink} />
            </div>
          ) : null}
        </Col>
      }
      visible={show}
      onCancel={() => {
        setError(null);
        onHide();
      }}
      footer={
        deleteState ? (
          <Row justify="end">
            <BJButton
              buttonType={ButtonTypes.Secondary}
              onClick={() => setDeleteState(false)}
            >
              No, cancel
            </BJButton>
            <BJButton
              buttonType={ButtonTypes.Delete}
              onClick={onDeleteRecord}
              loading={deleting}
              disabled={deleting}
            >
              Yes, delete!
            </BJButton>
          </Row>
        ) : (
          <Row justify="end">
            {enableDelete && (
              <BJButton
                buttonType={ButtonTypes.Delete}
                onClick={() => setDeleteState(true)}
              >
                Delete
              </BJButton>
            )}
            <BJButton
              buttonType={ButtonTypes.Save}
              key={formId ?? "formModal"}
              form={formId ?? "formModal"}
              disabled={saving || !enableSave}
              loading={saving}
              htmlType="submit"
            >
              Save
            </BJButton>
          </Row>
        )
      }
    >
      {renderErrors()}

      <Form
        id={formId ?? "formModal"}
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
      >
        {localeSupported && (
          <Radio.Group
            onChange={e => setLanguage(e.target.value)}
            buttonStyle={"solid"}
            size={"middle"}
            value={language}
            style={{ paddingBottom: 24 }}
          >
            {currentCountry.locales
              .sort((a, b) =>
                a.primary === b.primary ? 0 : b.primary ? 1 : -1
              )
              .map(locale => {
                return (
                  <Radio.Button key={locale?.key} value={locale?.key}>
                    {locale?.label}
                  </Radio.Button>
                );
              })}
          </Radio.Group>
        )}
        {typeof children === "function"
          ? children(
              currentCountry.locales.filter(
                locale => locale.key === language
              )[0]
            )
          : children}
      </Form>
    </Modal>
  );
};
