import moment from "moment";
import {
  CommonContentType,
  DisplayUnitType,
  ScreenInMobile,
} from "./commonEnums";
import { transformToCommonContent } from "../hooks/contentCollections/useFilterContent";
import { v4 as uuidv4 } from "uuid";
import { formatDateStringToTimeStamp } from "./commonUtils";
import _ from "lodash";
import { MilestonesService, PollService } from "../services";
import firebase from "../firebase";

type DownloadProps = {
  topic: "polls" | "milestones" | "dietary";
  polls?: Poll[];
  primaryLocale?: Locale;
  isMonthFilter?: boolean;
  getMilestonesByType?: () => Milestone[];
  dietaryAdvices?: FullDietaryAdvice[];
};

export type UploadProps = {
  topic: "polls" | "milestones" | "dietary";
  val?: string[][];
  articles?: FullArticle[];
  isMonthFilter?: boolean;
  pollService?: PollService;
  milestonesService?: MilestonesService;
  isChildPage?: boolean;
  createDietaryAdvice?: (
    data: Omit<DietaryAdviceV2, "id">
  ) => Promise<firebase.firestore.DocumentReference | null>;
};

//dynamic download csv handler
export const downloadCSV = ({
  topic,
  polls,
  primaryLocale,
  isMonthFilter,
  getMilestonesByType,
  dietaryAdvices,
}: DownloadProps) => {
  switch (topic) {
    case "polls":
      return downloadPollsData(polls, primaryLocale);
    case "milestones":
      return downloadMilestonesData(
        isMonthFilter,
        getMilestonesByType,
        primaryLocale
      );
    case "dietary":
      return downloadDietaryData(dietaryAdvices, primaryLocale);
  }
};
//dynamic upload csv handler
export const uploadCSV = ({
  topic,
  val,
  articles,
  pollService,
  milestonesService,
  isChildPage,
  createDietaryAdvice,
}: UploadProps) => {
  switch (topic) {
    case "polls":
      return convertCSVPollToData(val, articles, pollService);
    case "milestones":
      return convertCSVMilestoneToData(val, milestonesService, isChildPage);
    case "dietary":
      return convertCSVDietaryToData(val, createDietaryAdvice);
  }
};

const downloadPollsData = (polls: Poll[], primaryLocale: Locale) => {
  const csvData = [
    [
      "Translations",
      "With Percentage",
      "With CorrectAnswer",
      "Is MultiChoice",
      "Screens",
      "Is Active",
      "Answers",
      "Start Time",
      "End Time",
      "Scheduled At",
      "Extra",
      "ContentMap",
    ],
  ];
  polls.forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        return `${key}: title: ${
          t.translations[key]?.title ?? ""
        } \n ${key}: body: ${t.translations[key]?.body ?? ""}`;
      })
      .join("\n-------\n");

    //answers
    const answers = t?.answers?.reduce((acc, a) => {
      const trans = Object.keys(a.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: title: ${a.translations[key]?.title ?? ""}`;
        })
        .join("; ");

      const moreContentTrans = Object.keys(a.moreContent.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: title: ${
            a.moreContent.translations[key]?.title ?? ""
          }`;
        })
        .join("; ");

      const contentMap = Object.keys(a.moreContent.contentMap ?? "")
        .map(key => {
          return `contentID: ${key}, type: ${a.moreContent.contentMap[key].type}`;
        })
        .join("; ");

      const data = `${acc}${trans}** \n ${moreContentTrans} \n ~~${contentMap} \n-------\n`;

      return data;
    }, "");

    //screens
    const screens = t.screens?.reduce((acc, s) => {
      return `${acc}${s.position} - ${s.screen}\n`;
    }, "");

    //extra
    const extra = t.extra
      ? t.extra
          ?.map(e => {
            return `${e.audience} - ${e.range} - ${e.rangeUnit}\n`;
          })
          .join(", ")
      : "";

    csvData.push([
      translations.toString(),
      t.withPercentage?.toString(),
      t.withCorrectAnswer?.toString(),
      t.isMultiChoice?.toString(),
      screens,
      t.isActive?.toString(),
      answers,
      t.startTime ? moment(t.startTime).format("M/D/YY HH:mm") : "",
      t.endTime ? moment(t.endTime).format("M/D/YY HH:mm") : "",
      t.scheduledAt ? moment(t.scheduledAt).format("M/D/YY HH:mm") : "",
      extra,
    ]);
  });
  return csvData;
};

const downloadMilestonesData = (
  isMonthFilter: boolean,
  getMilestonesByType: () => Milestone[],
  primaryLocale: Locale
) => {
  const filter = isMonthFilter ? "Month" : "Week";
  const csvData = [
    [
      "Translations",
      filter,
      "Measurements",
      "Days",
      "Image Url",
      "ImageUrlFetus",
      "ImageUrlFruit",
      "Period",
      "Period Type",
      "Blurhash",
      "BlurhashFetus",
      "BlurhashFruit",
      "MonthOrWeek",
    ],
  ];

  (getMilestonesByType() as Milestone[]).forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        return `${key}: text:${
          t.translations[key]?.text ?? ""
        } \n ${key}: imageUrl:${
          t.translations[key]?.imageUrl ?? ""
        } \n ${key}: blurhash:${t.translations[key]?.blurhash ?? ""}`;
      })
      .join("\n-------\n");

    //week
    const week = t.week?.toString() ?? "";

    //month
    const month = t.month?.toString() ?? "";

    //days
    const days = t.days.reduce((acc, d) => {
      const translations = Object.keys(d.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: text: ${
            d.translations[key]?.text ?? ""
          } \n ${key}: imageUrl: ${d.translations[key]?.imageUrl ?? ""}`;
        })
        .join("\n+++++\n");

      return `${acc}${translations} \n*****\n day: ${d.day} \n imageUrl: ${d.imageUrl} \n text: ${d.text} \n-------\n`;
    }, "");

    const measurements = JSON.stringify(t.measurements) ?? "";

    //imageUrl
    const imageUrl = t.imageUrl ?? "";

    //imageUrlFetus
    const imageUrlFetus = t.imageUrlFetus ?? "";

    //imageUrlFruit
    const imageUrlFruit = t.imageUrlFruit ?? "";

    //period
    const period = t.period ?? "";

    //periodType
    const periodType = t.periodType ?? "";

    //blurhash
    const blurhash = t.blurhash ?? "";

    //blurhashFetus
    const blurhashFetus = t.blurhashFetus ?? "";

    //blurhashFruit
    const blurhashFruit = t.blurhashFruit ?? "";

    const monthOrWeek = isMonthFilter ? "month" : "week";

    csvData.push([
      translations.toString(),
      !isMonthFilter ? week : month,
      measurements,
      days,
      imageUrl,
      imageUrlFetus,
      imageUrlFruit,
      period.toString(),
      periodType,
      blurhash,
      blurhashFetus,
      blurhashFruit,
      monthOrWeek,
    ]);
  });
  return csvData;
};

const downloadDietaryData = (
  dietaryAdvices: FullDietaryAdvice[],
  primaryLocale: Locale
) => {
  const csvData = [
    ["Translations", "Category ID", "Indicator", "Promoted", "Import ID"],
  ];

  dietaryAdvices.forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        const tags =
          t.translations[key]?.tagWords?.map(tw => tw)?.join("* ") ?? "";

        return `${key}: name: ${
          t.translations[key]?.name ?? ""
        } \n ${key}: description: ${
          t.translations[key]?.description ?? ""
        } \n ${key}: tagWords: ${tags}`;
      })
      .join("\n-------\n");

    //category
    const categoryId = t.categoryId ?? "";

    //indicator
    const indicator = t.indicator ?? "";

    //promoted
    const promoted = t.promoted?.toString() ?? "";

    //importId
    const importId = t.importId ?? "";

    csvData.push([
      translations.toString(),
      categoryId,
      indicator,
      promoted,
      importId,
    ]);
  });
  return csvData;
};

const convertToTags = (v: string[]) => {
  const tags = v
    ?.filter(e => e !== " ")
    ?.map(t => {
      return t?.replace(/\s/g, "");
    });
  return tags;
};

//ConvertCSVData
export const convertToTranslations = (
  v: string[],
  topic?: UploadProps["topic"]
) => {
  const separate = v[0].split("\n-------\n");
  const t = separate.map(s => {
    let key;
    let title;
    let name;
    let body;
    let description;
    let tagWords: string[];
    let text;
    let imageUrl;
    let blurhash;
    if (topic === "polls") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      title = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      body = s.split(":")[4]?.trim();

      return {
        [key]: {
          title: title,
          body: body,
        },
      };
    } else if (topic === "milestones") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      text = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      imageUrl = s.split(":")[5]?.replace("\n", "")?.includes(key)
        ? s.split(":")[5]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[5]?.replace("\n", "")?.trim();
      blurhash = s.split(":")[7]?.trim();

      return {
        [key]: {
          text: text ?? "",
          imageUrl: `https:${imageUrl}` ?? "",
          blurhash: blurhash ?? "",
        },
      };
    } else if (topic === "dietary") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      name = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      description = s.split(":")[4]?.replace("\n", "")?.includes(key)
        ? s.split(":")[4]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[4]?.replace("\n", "")?.trim();
      tagWords = convertToTags(s.split(":")[6]?.split("*"));

      return {
        [key]: {
          name: name ?? "",
          description: description ?? "",
          tagWords: tagWords ?? [],
        },
      };
    }
  });

  const reduced = t.reduce((acc, t) => {
    return { ...acc, ...t };
  }, {} as any);

  return reduced;
};

const answersTranslations = (value: string[]) => {
  const answers = value[0].split(";");
  const a = answers.map(ans => {
    const key = ans.split(":")[0]?.replace(/\s/g, "");
    const title = ans.split(":")[2]?.replace(/\s/g, "")?.trim();

    return {
      [key]: {
        title: title,
      },
    };
  });

  const reduced: Answer["translations"] = a.reduce((acc, a) => {
    return { ...acc, ...a };
  });

  return reduced;
};

const moreContentTranslations = (value: string[]) => {
  const moreContent = value[1].split(";");

  const a = moreContent.map(mc => {
    const key = mc.split(":")[0]?.replace(/\s/g, "");
    const title = mc.split(":")[2]?.includes("~~")
      ? mc
          .split(":")[2]
          ?.replace("~~contentID", "")
          ?.replace("\n", "")
          ?.replace("~~", "")
          ?.trim()
      : mc.split(":")[2]?.trim();

    return {
      [key]: {
        title: title,
      },
    };
  });

  const reduced: Answer["moreContent"]["translations"] = a.reduce((acc, a) => {
    return { ...acc, ...a };
  });

  return reduced;
};

const contentMap = (value: string[], articles: FullArticle[]) => {
  const content = value[1].split("~~")[1];
  const result = content
    .split(";")
    .filter(c => c.includes("contentID"))
    .map(c => {
      const contentID = c
        .split(":")[1]
        ?.replace(", type", "")
        ?.replace(/\s/g, "");

      const type = c.split(":")[2]?.replace("\n", "")?.replace(/\s/g, "");
      if (!contentID || !type) {
        return;
      }

      switch (type) {
        case CommonContentType.ARTICLE:
          const article = articles.find(a => a.id === contentID);
          const commonContent = transformToCommonContent(
            article as any,
            0,
            CommonContentType.ARTICLE
          );
          return commonContent;
      }
    });

  return result;
};

const convertToAnswers = (v: string[], articles: FullArticle[]) => {
  const data = v[6].split("\n-------\n");
  data.pop();
  const main = data.map(d => {
    const value = d.split("**");

    //answers
    const answersTrans = answersTranslations(value);

    //morecontentTrans
    const moreContentTrans = moreContentTranslations(value);

    const content = contentMap(value, articles);

    const val: Answer = {
      id: uuidv4(),
      sortOrder: 0,
      percentageAnswered: 0,
      correctAnswer: false,
      translations: {
        ...answersTrans,
      },
      moreContent: {
        translations: {
          ...moreContentTrans,
        },
        content: content[0] !== undefined ? content : [],
        contentMap:
          content[0] !== undefined
            ? content.reduce((acc, item) => {
                acc[item?.id] = item;
                return acc;
              }, {} as { [key: string]: any })
            : {},
      },
    };

    return val;
  });

  return main;
};

const convertToExtras = (v: string[]) => {
  const result = v[10]?.split("\n,")?.reduce((acc, e) => {
    const value = e?.split(" - ");
    const range = value[1]?.split(",").map(r => parseInt(r));

    return [
      ...acc,
      {
        audience: value[0]?.replace(/\s/g, ""),
        range: range,
        rangeUnit: value[2]?.replace("\n", ""),
      },
    ];
  }, [] as any);

  return result;
};

//For milestones days translations
const convertToDaysTranslations = (v: string) => {
  //translation
  const trans = v
    ?.split("\n*****\n")[0]
    ?.split("\n+++++\n")
    .map(t => {
      const key = t?.split(":")[0]?.replace(/\s/g, "");
      const text = t?.split(":")[2]?.replace(key, "")?.trim();
      const imageUrl = t?.split(":")[5]?.trimEnd();

      return {
        [key]: {
          text: text,
          imageUrl: `https:${imageUrl}`,
        },
      };
    });

  const reduced: MilestoneDay["translations"] = trans.reduce((acc, t) => {
    return { ...acc, ...t };
  }, {} as any);

  return reduced;
};

//Convert to Days Array
const convertToDays = (v: string[]) => {
  const days = v[4]?.split("\n-------\n");
  days?.pop();

  const result = days.map(d => {
    const dayTrans = convertToDaysTranslations(d);

    const day = d
      ?.split("\n*****\n")[1]
      ?.split(":")[1]
      ?.replace("imageUrl", "")
      ?.trim();
    const imageUrl = `https:${d
      ?.split("\n*****\n")[1]
      ?.split(":")[3]
      ?.replace("text", "")
      .trim()}`;
    const text = d?.split("\n*****\n")[1]?.split(":")[4]?.trim();

    const val: MilestoneDay = {
      id: uuidv4(),
      day: parseInt(day),
      imageUrl: imageUrl,
      text: text,
      translations: dayTrans,
    };

    return val;
  });

  return result;
};

//convertCSVPollToData
export const convertCSVPollToData = (
  val: string[][],
  articles: FullArticle[],
  pollService: PollService
) => {
  const value = val.map(async v => {
    //translations
    const trans = convertToTranslations(v, "polls");
    //answers
    const answers = convertToAnswers(v, articles);
    //extras
    const extra = convertToExtras(v);

    const val = {
      translations: {
        ...trans,
      },
      withPercentage: v[1]?.toLowerCase().includes("true") ? true : false,
      withCorrectAnswer: v[2]?.toLowerCase().includes("true") ? true : false,
      isMultiChoice: v[3]?.toLowerCase().includes("true") ? true : false,
      isActive: v[5]?.toLowerCase().includes("true") ? true : false,
      answers: answers,
      startTime: v[7] ? formatDateStringToTimeStamp(v[7], false) : null,
      endTime: v[8] ? formatDateStringToTimeStamp(v[8], false) : null,
      scheduledAt: v[9] ? formatDateStringToTimeStamp(v[9], false) : null,
      extra: extra,
    };

    const _poll: Partial<Poll> = {
      translations: val.translations,
      withPercentage: val.withPercentage,
      withCorrectAnswer: val.withCorrectAnswer,
      isMultiChoice: val.isMultiChoice,
      screens: [
        { screen: ScreenInMobile.Child, position: 0 },
        { screen: ScreenInMobile.Pregnancy, position: 0 },
      ],
      isActive: val.isActive,
      answers: val.answers ?? [],
      startTime: val.startTime,
      endTime: val.endTime,
      scheduledAt: val.scheduledAt,
      extra: val.extra ?? [],
      segment: {
        type: !_.isEmpty(val.extra) ? val.extra[0].audience : null,
        rangeUnit: DisplayUnitType.Day,
        range: !_.isEmpty(val.extra) ? val.extra[0].range : null,
      },
    };

    await pollService?.create({
      ..._poll,
      createdAt: formatDateStringToTimeStamp(moment().toISOString(), false),
    });

    return _poll;
  });

  return value;
};

export const convertCSVMilestoneToData = (
  val: string[][],
  milestonesService: MilestonesService,
  isChildPage: boolean
) => {
  val.map(async v => {
    const trans = convertToTranslations(v, "milestones");

    const measurements = JSON.parse(v[2]);
    const days = convertToDays(v);
    const imageUrl = v[5];
    const imageUrlFetus = v[6];
    const imageUrlFruit = v[7];
    const period = parseInt(v[8]);
    const periodType = v[9];
    const blurhash = v[10];
    const blurhashFetus = v[11];
    const blurhashFruit = v[12];
    const isMonth = v[13]?.toLowerCase().includes("month");

    const val = {
      translations: {
        ...trans,
      },
      week: !isMonth && parseInt(v[1]),
      month: isMonth && parseInt(v[1]),
      days: days,
      imageUrl: imageUrl,
      imageUrlFetus: imageUrlFetus,
      imageUrlFruit: imageUrlFruit,
      period: period ?? 0,
      periodType: periodType,
      blurhash: blurhash,
      blurhashFetus: blurhashFetus,
      blurhashFruit: blurhashFruit,
    };

    const isChildMonth = isChildPage && isMonth;
    const isChildWeek = isChildPage && !isMonth;

    const milestoneData: Omit<Milestone, "translations"> = isChildMonth
      ? {
          month: val.month,
          imageUrl: val.imageUrl,
          blurhash: val.blurhash,
          days: val.days,
        }
      : isChildWeek
      ? {
          week: val.week,
          imageUrl: val.imageUrl,
          blurhash: val.blurhash,
          days: val.days,
        }
      : {
          week: val.week,
          imageUrl: val.imageUrl,
          blurhash: val.blurhash,
          days: val.days,
          imageUrlFruit: val.imageUrlFruit,
          blurhashFruit: val.blurhashFruit,
          imageUrlFetus: val.imageUrlFetus,
          blurhashFetus: val.blurhashFetus,
          weight: measurements.weight.value,
          length: measurements.length.value,
          weightUnit: measurements.weight.displayValue,
          lengthUnit: measurements.length.displayValue,
          measurements,
        };

    await milestonesService?.create(
      {
        ...milestoneData,
        translations: val.translations,
      },
      isChildPage
    );
  });
};

export const convertCSVDietaryToData = (
  val: string[][],
  createDietaryAdvice: UploadProps["createDietaryAdvice"]
) => {
  val.map(async v => {
    //translations
    const trans = convertToTranslations(v, "dietary");
    //category ID
    const categoryId = v[1];
    //indicator
    const indicator = v[2] as DietaryAdviceV2["indicator"];
    //promoted
    const promoted = v[3]?.toLowerCase()?.includes("true");
    //importId
    const importId = v[4];

    const val = {
      translations: {
        ...trans,
      },
      categoryId: categoryId,
      indicator: indicator,
      promoted: promoted,
      importId: importId,
    };

    const _dietaryAdvice: Omit<DietaryAdviceV2, "id"> = {
      categoryId: val.categoryId,
      indicator: val.indicator,
      importId: val.importId,
      promoted: val.promoted ?? false,
      translations: val.translations,
    };

    await createDietaryAdvice(_dietaryAdvice);
  });
};
